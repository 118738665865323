<template>
  <div class="processing-page">
    <StepProcess :active="2" />
    <div class="processing-page__container">
      <div v-if="jobId !== undefined">
        <div v-if="data.status === 'Processing'">
          <SmallLoader
            :content="
              `You job (#${jobId}) has started. This page will automatically refresh
              when your job request is completed. You can also look up the status of
              your job with the job ID.`
            "
          />
        </div>
        <div class="wrapper-info" v-if="data.status !== 'Processing'">
          <div class="info">
            Your job (<span class="job-id">#{{ data.id }}</span>) has
            <span class="job-status">{{ data.status.toLowerCase() }}</span>
            .
          </div>
          <v-btn
            v-if="data.status === 'Completed'"
            :class="['result-button', data.status.toLowerCase()]"
            @click="navResult(data.id)"
            >Results</v-btn
          >
        </div>
      </div>
      <div v-else>
        <SmallLoader :content="'We are creating a job for your request…'" />
      </div>
      <hr />

      <v-form
        v-if="jobId !== undefined"
        @submit.prevent="subscribeNotify"
        lazy-validation
        v-model="valid"
      >
        <v-text-field
          v-model="email"
          required
          placeholder="Your Email Address"
          :rules="emailRules"
        ></v-text-field>
        <v-btn
          class="search"
          type="submit"
          :disabled="!valid || email.length === 0"
        >
          <v-icon class="search-icon" />
          <span v-if="data.status === 'Processing'">Notify by Email</span>
          <span v-else
            >This link job with <br />
            your email</span
          >
        </v-btn>
      </v-form>
    </div>
    <div @click="back"><BackButton /></div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton';
import StepProcess from '@/components/StepProcess';
import SmallLoader from '@/components/SmallLoader';
import { bus } from '../bus.js';
import timediff from 'timediff';
import { mapActions } from 'vuex';

const VUE_APP_URL = process.env.VUE_APP_URL || '';
export default {
  name: 'ProcessingPage',
  components: {
    BackButton,
    StepProcess,
    SmallLoader
  },
  data() {
    return {
      valid: true,
      data: { status: 'Processing' },
      email: '',
      msgTooltip: '',
      emailRules: [
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          'E-mail must be valid'
      ]
    };
  },
  computed: {
    jobId() {
      return this.data?.id || this.$route.query?.job_id || undefined;
    }
  },
  methods: {
    ...mapActions(['setProcessType']),
    subscribeNotify() {
      const jobId = this.data.id || 0;
      this.axios
        .post(VUE_APP_URL + `/api/v1/jobs/${jobId}/subscribe`, {
          email: this.email
        })
        .then(result => {
          console.log(result);
          this.$vToastify.info({
            body: 'Subscribed',
            title: ' ',
            duration: 1000,
            icon: ' ',
            type: 'info'
          });
        })
        .catch(error => {
          console.log(error);
          this.$vToastify.info({
            body: 'An error has occurred',
            title: ' ',
            duration: 1000,
            icon: ' ',
            type: 'error'
          });
        });
    },
    back() {
      bus.$off('onmessage');
      this.$router.go(-1);
    },
    navResult() {
      this.$router.push({
        name: 'OutputPage',
        query: { job_id: this.data.id || 0, type: this.$route.query.type || '' }
      });
    }
  },
  created() {
    if (this.jobId !== undefined) {
      const refetchJob = setInterval(async () => {
        try {
          const response = await this.axios.get(
            VUE_APP_URL + `/api/v1/jobs/${this.jobId}`
          );

          const data = response.data;
          data.time = timediff(
            data.submission_datetime,
            data.completion_datetime,
            'ms'
          );
          this.setProcessType(data.type);
          this.data = data;
        } catch (e) {
          console.log('error', e);
        } finally {
          clearInterval(refetchJob);
        }
      }, 10000);
    }
    bus.$on('onmessage', event => {
      if (typeof event.data === 'string') {
        const data = JSON.parse(event.data);
        setTimeout(() => {
          if (data && data.progress !== undefined) {
            bus.data = {
              ...data,
              status: 'Processing'
            };
            this.data = bus.data;
            this.setProcessType(this.data.type);
          }
        }, 1000);

        setTimeout(() => {
          if (data.status == 'Completed') {
            bus.data = data;
            bus.data.time = timediff(
              data.submission_datetime,
              data.completion_datetime,
              'ms'
            );
            this.data = bus.data;
          } else if (data.status == 'Failed') {
            bus.data = data;
            this.data = bus.data;
            this.setProcessType(this.data.type);
          }
        }, 2000);
      } else if (event.data instanceof Blob) {
        let reader = new FileReader();
        reader.onload = () => {
          bus.data.text = reader.result;
        };
        reader.readAsText(event.data);
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.processing-page {
  &__container {
    border: 1px solid #d4d4d4;
    box-sizing: border-box;
    border-radius: 10px;
    height: 100%;

    margin: 0 10vw;
    padding: 50px 70px;
    min-width: 460px;

    @media (max-width: 767px) {
      min-width: unset;
      margin: 0 10px;
      padding: 62px 20px 65px;
    }

    .wrapper-info {
      display: flex;
    }

    .info {
      display: inline;
      width: 80%;
      font-family: Karla;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 36px;

      color: #8a8a8a;

      .job {
        &-id,
        &-time {
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          color: var(--kern-blue);
        }

        &-status {
          margin-right: -5px;
        }
      }
    }

    hr {
      border: 1px solid #d4d4d4;
      margin-top: 56px;
    }

    form {
      max-width: 735px;
      margin-top: 56px;
      background: #ffffff;
      border: 1px solid #d4d4d4;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      height: 80px;
      align-items: center;
      padding-right: 4px;

      ::v-deep .v-text-field > .v-input__control > {
        .v-input__slot:after,
        .v-input__slot:before {
          display: none;
        }
      }

      ::v-deep .v-input {
        height: 80px;
        padding-left: 18px;
        padding-top: 0;
        margin-top: 0;

        font-family: Karla;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 26px;
        color: #8a8a8a;

        input {
          margin-top: 27px;
          height: 80px;
        }
      }

      .v-btn {
        width: 287px;
        height: 70px;
        box-shadow: none;

        background: #f1505f;
        border-radius: 6px;

        i {
          width: 37px;
          height: 37px;
          background: url('../assets/notify.svg');
        }

        span {
          font-style: normal;
          font-weight: bold;
          font-size: 22px;
          line-height: 26px;
          text-transform: uppercase;
          padding-left: 20px;

          color: #ffffff;
        }
      }
    }
  }

  .popup {
    background-image: url('../assets/info-popup.svg');
    width: 20px;
    height: 20px;
    padding-left: 20px;

    opacity: 0.6;
    margin-left: 5px;
    cursor: pointer;
    display: inline;
  }
}
</style>
