<template>
  <div class="horizontal">
    <div class="info">
      {{ content }}
    </div>
    <div class="loader small"></div>
  </div>
</template>

<script>
export default {
  name: 'SmallLoader',
  props: {
    content: {
      type: String,
      default: () => ''
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid var(--kern-blue); /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

.loader.small {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid var(--kern-blue); /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  margin: 0px 20px;

  @media (max-width: 1089px) {
    margin: 0;
  }
}

.horizontal {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: auto;
  align-items: center;
}

.info {
  font-family: Karla;
  font-size: 22px;
  line-height: 1.5;
  color: #8a8a8a;
  width: 90%;
}
</style>
